:root {
    font-family: "Open Sans", "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
    font-size: 16px;
}

html,
body {
    margin: 0;
    height: 100%;
    font-family: "Open Sans", "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
    font-size: 16px;
}

body ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

/* Track */
body ::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
body ::-webkit-scrollbar-thumb {
    background: #333333;
}

/* Handle on hover */
body ::-webkit-scrollbar-thumb:hover {
    background: #000000;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font-family: inherit;
}

.mobileonly {
    display: none;
}

#root {
    height: 100%;
    .app-container {
        background: var(--app-background, #ffffff);
        color: var(--app-text-color, #000000);
        height: 100%;
        nav.nav-original {
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            background: var(--nav-background);
            box-shadow: 0 -2px 25px rgba(0, 0, 0, 0.1);
            z-index: 1000;
            border-top: 2px solid var(--nav-link-border-color);

            .color-bar {
                height: var(--color-bar-height);
                background-image: linear-gradient(90deg, var(--color-bar-1), var(--color-bar-2));
                width: 100%;
            }

            .navigation {
                display: flex;
                justify-content: space-between;
                .logo {
                    display: flex;
                    max-width: 12rem;
                    height: 100%;
                    align-items: center;
                    gap: 5px;
                    background: var(--nav-logo-background);
                    padding: 0 1rem;
                    img {
                        width: 50%;
                    }
                    img:only-child {
                        width: 100%;
                    }
                }
                .logo.small {
                    img {
                        max-height: 40px;
                    }
                }
                .logo.medium {
                    img {
                        max-height: 60px;
                    }
                }
                .logo.large {
                    img {
                        max-height: 70px;
                    }
                }
                .links {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-around;
                    align-items: center;
                    img {
                        height: 1.5rem;
                    }
                    a,
                    .link {
                        padding: 15px 20px;
                        font-size: 1.3rem;
                        color: var(--nav-link-color);
                        font-weight: 700;
                        flex-grow: 1;
                        text-align: center;
                        &:hover {
                            color: var(--nav-link-color-hover);
                            background-color: var(--nav-background-hover);
                        }
                        &.active,
                        &:active {
                            color: var(--nav-link-color-active);
                            background-color: var(--nav-background-active);
                            text-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
                        }
                    }
                }
            }
        }

        .copyright {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            color: var(--nav-copyright-color);
            font-size: .8rem;
            padding: 0 10px;
            opacity: .5;
            margin-top: auto;
            .item {
                margin: 0 10px;
            }
        }
        main {
            height: 100%;
            padding-bottom: 68px;
            width: 100%;
            overflow-y: auto;
            &.vidbg {
                background-color: black;
            }
            &#unboxing, &#crafting {

                .inner {
                    .card {
                        background-color: #eeedef;
                        padding: 0.5rem;
                        border-radius: 10px;
                        box-sizing: border-box;

                        .image-container {
                            display: inline-block;
                            width: 260px;
                            max-width: 100%;
                            height: 260px;
                            max-height: 260px;
                            perspective: 500px;

                            .preview {
                                position: absolute;
                                text-align: center;
                                height: inherit;
                                max-height: 100%;
                                width: inherit;
                                max-width: 100%;
                                transition: all .7s;
                                transform-style: preserve-3d;
                                backface-visibility: visible;
                                padding: 1rem 0;
                                border-radius: 10px;
                                background-color: var(--unpack-card-inner-background-color, #eeedef);

                                img, video {
                                    max-width: 100%;
                                    max-height: 100%;
                                }
                            }

                            .image-frame {
                                height: inherit;
                                max-height: 100%;
                                width: inherit;
                                max-width: 100%;
                                position: relative;
                                img, video {
                                    max-height: 100%;
                                    max-width: 100%;
                                    position: absolute;
                                    top: 0;
                                    bottom: 0;
                                    left: 0;
                                    right: 0;
                                    margin: auto;
                                }
                            }
                        }
                    }
                }
            }
            &#unboxing {
                .inner {
                    .card {
                        background-color: #eeedef;
                        padding: 0.5rem;
                        border-radius: 10px;
                        box-sizing: border-box;

                        .image-container {
                            .preview {
                                opacity: 0;

                                &.reveal {
                                    animation: fadeIn 5s;
                                    -webkit-animation: fadeIn 5s;
                                    -moz-animation: fadeIn 5s;
                                    -o-animation: fadeIn 5s;
                                    -ms-animation: fadeIn 5s;

                                    opacity: 1;
                                }

                                &.revealed {
                                    opacity: 1;
                                }
                            }

                            @keyframes fadeIn {
                                0% {opacity:0;}
                                100% {opacity:1;}
                            }

                            @-moz-keyframes fadeIn {
                                0% {opacity:0;}
                                100% {opacity:1;}
                            }

                            @-webkit-keyframes fadeIn {
                                0% {opacity:0;}
                                100% {opacity:1;}
                            }

                            @-o-keyframes fadeIn {
                                0% {opacity:0;}
                                100% {opacity:1;}
                            }

                            @-ms-keyframes fadeIn {
                                0% {opacity:0;}
                                100% {opacity:1;}
                            }
                        }
                    }
                }
            }
            section {
                padding: 1rem;
                &.full {
                    min-height: calc(100% - 50px);
                }
                &.center {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                &.promo-video {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 0;
                    width: 100%;
                    height: 100%;
                    max-height: calc(100vh);
                    video {
                        width: 100%;
                        height: 100%;
                        max-height: calc(100vh);
                        background-color: black;
                    }
                }
                &.item-drops {
                    min-height: calc(100% - 50px);
                    text-align: center;
                    .inner {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-wrap: wrap;
                        .card {
                            margin: 10px;

                            .name {
                                color: black;
                                font-size: 1.5rem;
                                font-weight: 600;
                                margin-bottom: 5px;
                            }
                            .rarity {
                                color: black;
                                font-weight: 300;
                                font-size: 1.2rem;
                            }
                        }
                    }

                    button {
                        margin-top: 15px;
                        background: var(--shop-packlist-item-button-background);
                        outline: none;
                        border: none;
                        color: var(--shop-packlist-item-button-color);
                        padding: 10px 25px;
                        border-radius: 10px;
                        cursor: pointer;
                        font-size: 1.2rem;
                        font-weight: 600;
                        width: 300px;
                        max-width: 100%;
                        margin-bottom: 25px;
                        &:disabled {
                            filter: opacity(0.5) brightness(0.8);
                        }
                        &:hover:not(:disabled) {
                            filter: brightness(1.2);
                        }
                    }
                }
                &.topnav {
                    width: 100%;
                    padding: 0 1rem;
                    background: var(--topnav-background);
                    border-bottom: 2px solid var(--topnav-border-color);

                    .signin {
                        display: flex;
                        .logo.small {
                            height: 40px;
                        }
                        .logo.medium {
                            height: 80px;
                        }
                        .logo.large {
                            height: 100px;
                        }
                        .links {
                            margin-top: auto;
                            margin-bottom: auto;
                        }
                        .right {
                            margin-left: auto;
                            display: flex;
                            align-items: center;

                            .hasbro-link {
                                display: none;
                            }
                        }
                        a.action {
                            color: var(--topnav-signin-button-color, #ffffff);
                            background: var(--topnav-signin-button-background, #000000);
                            font-weight: 700;
                            cursor: pointer;
                        }
                        > button {
                            margin-left: auto;
                            color: var(--topnav-signin-button-color);
                            border: 2px solid var(--topnav-signin-button-border-color);
                            background: var(--topnav-signin-button-background);
                            padding: 10px 50px;
                            font-size: 1.2rem;
                            border-radius: 15px;
                            opacity: 0.7;
                            cursor: pointer;
                            transition: opacity 0.1s;
                            outline: none;
                            &:hover {
                                opacity: 1;
                            }
                        }
                        .link {
                            margin-left: .5rem;
                            border: none;
                            padding: .5rem .75rem;
                            color: var(--topnav-link-color, #000000);
                            background: var(--topnav-link-background);
                            font-weight: 600;
                            font-size: 1rem;
                            border-radius: .75em;
                            line-height: 1;
                            cursor: pointer;
                            outline: none;
                            text-decoration: none;
                            white-space: nowrap;
                            &:hover {
                                opacity: .95;
                            }
                        }
                        .legacylink {
                            button {
                                margin-left: .5rem;
                                border: none;
                                padding: .5rem .75rem;
                                color: var(--topnav-legacy-button-color);
                                background: var(--topnav-legacy-button-background);
                                font-weight: 600;
                                font-size: .9rem;
                                border-radius: .75rem;
                                line-height: 1;
                                cursor: pointer;
                                outline: none;
                                &:hover {
                                    opacity: .95;
                                }
                            }
                        }
                        .profile {
                            display: flex;
                            flex-flow: column;
                            align-items: center;
                            justify-content: center;
                            text-align: right;
                            margin-left: .5rem;
                            height: 100%;
                            font-size: 0.8rem;
                            color: var(--topnav-profile-color, #ffffff);
                            text-decoration: none;
                            img {
                                border-radius: .75rem;
                                background: var(--topnav-profile-image-background, #ffffff);
                                padding: .2rem .3rem;
                                height: 30px;
                            }
                            &:hover {
                                img {
                                    opacity: .95;
                                }
                            }
                        }
                    }
                }
                &.topnav.hasbro {
                    padding: 0;
                    .signin {
                        .logo-link {
                            display: flex;
                            align-items: center;
                            padding: 0 1rem;
                        }
                        .right {
                            align-items: stretch;
                            .link {
                                padding: 1.5rem;
                                border-radius: 0;
                                margin: 0;
                                color: white;
                            }
                            .hasbro-link {
                                display: block;
                            }
                            .profile {
                                margin: 0;
                                padding: 0 1.5rem;
                                background-color: var(--topnav-link-color, #000000);
                                color: white;
                                padding: 0.7rem 1.2rem 0.3rem;
                            }
                            a {
                                display: none;
                            }
                        }
                    }
                }
                &.loader {
                    min-height: 58vh;
                    display: flex;
                    justify-content: center;
                    flex-flow: column;
                    align-items: center;
                    text-align: center;
                    .bar {
                        width: 280px;
                        margin: 15px 0;
                        max-width: 100%;
                        height: 8px;
                        border: 1px solid #999999;
                        .bar-inner {
                            height: 100%;
                            background: linear-gradient(90deg, var(--color-bar-1), var(--color-bar-2));
                            max-width: calc(100% - 2px);
                        }
                    }
                }
                &.title-section {
                    text-align: center;
                    h1 {
                        color: var(--section-title-color);
                        font-size: 3rem;
                        margin-bottom: 1rem;
                        &.faq {
                            font-weight: 900;
                        }
                        .highlight {
                            color: var(--section-title-highlight-color);
                        }
                    }
                    p {
                        color: var(--section-text-color);
                        width: 550px;
                        margin: 0 auto;
                        font-weight: 600;
                        max-width: 100%;
                    }
                }
                &.leaderboard {
                    padding-bottom: 15rem;
                    .top {
                        display: flex;
                        align-items: center;
                        width: 750px;
                        max-width: 100%;
                        margin: 0 auto;
                        font-size: .8rem;
                        padding-bottom: .5rem;
                        .refresh {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            cursor: pointer;
                            margin-left: auto;
                            font-size: .8rem;
                            &:hover {
                                text-decoration: underline;
                            }
                            img {
                                height: .8rem;
                                width: .8rem;
                                padding: 0 .2rem;
                            }
                            &.loading {
                                img {
                                    animation: spin infinite 2s;
                                }
                            }
                        }
                    }
                    .schemas {
                        display: flex;
                        flex-wrap: nowrap;
                        justify-content: center;
                        margin-bottom: 25px;
                        .schema {
                            display: flex;
                            background: #333333;
                            margin-right: 10px;
                            justify-content: center;
                            align-items: center;
                            min-width: 120px;
                            height: 40px;
                            font-size: 1.2rem;
                            color: white;
                            font-weight: 700;
                            border-radius: 10px;
                            padding: 10px 40px;
                            text-decoration: none;
                            opacity: 0.6;
                            cursor: pointer;
                            filter: brightness(0.8);
                            transition: filter 0.2s, opacity 0.2s;
                            &:hover {
                                opacity: 0.7;
                                filter: brightness(1);
                            }
                            &.active {
                                opacity: 1;
                                filter: brightness(1.3);
                            }
                        }
                    }
                    .board {
                        position: relative;
                        max-width: 100%;
                        margin: 0 auto;
                        width: 750px;
                        .row {
                            display: flex;
                            align-items: center;
                            margin-bottom: .5rem;
                            border-radius: .5rem;
                            background-color: #eeeeee;
                            height: 2.5rem;
                            .position {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                padding: 0 1.5rem;
                                border-radius: .5rem;
                                margin-right: .5rem;
                                font-size: .8rem;
                                height: 100%;
                                background-color: rgba(0, 0, 0, 0.1);
                                font-weight: 700;
                            }
                            .wallet {
                                font-weight: 700;
                                text-decoration: none;
                            }
                            .lastupdate {
                                opacity: .5;
                                font-size: .8rem;
                                margin-left: .5rem;
                            }
                            .score {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                margin-left: auto;
                                padding: 0 .5rem;
                                border-radius: .5rem;
                                margin-right: .5rem;
                                font-size: .8rem;
                                font-weight: 700;
                            }
                            .update {
                                position: absolute;
                                left: 100%;
                                padding: 0 .5rem;
                                cursor: pointer;
                                img {
                                    height: 1rem;
                                    width: 1rem;
                                }
                                &.loading {
                                    img {
                                        animation: spin infinite 2s;
                                    }
                                }
                            }
                            &:nth-child(1) {
                                .position {
                                    color: white;
                                    background-color: var(--color-bar-2);
                                }
                            }
                            &:nth-child(2) {
                                .position {
                                    color: white;
                                    background-color: var(--color-bar-2);
                                }
                            }
                            &:nth-child(3) {
                                .position {
                                    color: white;
                                    background-color: var(--color-bar-2);
                                }
                            }
                            &.self {
                                color: white;
                                background-color: var(--color-bar-2);
                                .position {
                                    background-color: rgba(0, 0, 0, 0.25);
                                }
                            }
                        }
                    }
                }
                &.labs {
                    .recipe-header {
                        display: flex;
                        justify-content: space-between;
                        color: #999999;
                        font-weight: 900;
                        font-size: 2rem;
                        opacity: .5;
                        .left {
                            color: red;
                        }
                        .right {
                            color: rgb(26, 221, 0);
                            color: var(--color-bar-1);
                        }
                    }
                    .schemas {
                        display: flex;
                        flex-wrap: nowrap;
                        justify-content: center;
                        margin-bottom: 25px;
                        .schema {
                            display: flex;
                            background: #333333;
                            margin-right: 10px;
                            justify-content: center;
                            align-items: center;
                            min-width: 120px;
                            height: 40px;
                            font-size: 1.2rem;
                            color: white;
                            font-weight: 700;
                            border-radius: 10px;
                            padding: 10px 40px;
                            text-decoration: none;
                            opacity: 0.6;
                            cursor: pointer;
                            filter: brightness(0.8);
                            transition: filter 0.2s, opacity 0.2s;
                            &:hover {
                                opacity: 0.7;
                                filter: brightness(1);
                            }
                            &.active {
                                opacity: 1;
                                filter: brightness(1.3);
                            }
                        }
                    }
                    .templates {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        .template {
                            margin: 10px;
                            cursor: pointer;
                            .preview {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 250px;
                                width: 250px;
                                background-color: #eeeeee;
                                border-radius: 10px;
                                margin-bottom: 5px;
                                img, video {
                                    max-height: calc(100% - 20px);
                                    max-width: calc(100% - 20px);
                                    transition: transform .2s;
                                    transform: scale(0.95);
                                }
                            }
                            &:not(.possible) {
                                .preview {
                                    opacity: .7;
                                    filter: grayscale(1);
                                }
                            }
                            text-align: center;
                            .name {
                                font-weight: 600;
                            }
                            .recipe-count {
                                font-size: .8rem;
                            }
                            &:hover {
                                .preview {
                                    img, video {
                                        transform: scale(1.05);
                                    }
                                }
                            }
                        }
                    }
                    .mb {
                        margin-bottom: 25px;
                    }
                    .back {
                        font-weight: 600;
                        padding: 5px 10px;
                        border: none;
                        background-color: #eeeeee;
                        color: black;
                        font-size: .8rem;
                        outline: none;
                        cursor: pointer;
                        &:hover {
                            filter: brightness(.9);
                        }
                        &[disabled] {
                            cursor: not-allowed;
                            opacity: .5;
                        }
                    }
                    .recipes {
                        display: flex;
                        flex-flow: column;
                        .recipe {
                            display: flex;
                            overflow-x: auto;
                            align-items: flex-start;
                            padding: 25px;
                            margin-bottom: 25px;
                            background-color: #eeeeee;
                            .items, .results {
                                display: flex;
                                flex-shrink: 0;
                                &.items {
                                    flex-shrink: 1;
                                    flex-flow: wrap;
                                    .item {
                                        width: 250px;
                                        margin: 0;
                                        padding: 5px;
                                        box-sizing: border-box;
                                    }
                                }
                                &.results {
                                    margin-left: auto;
                                    flex-shrink: 1;
                                    flex-flow: wrap;
                                    .item {
                                        width: 250px;
                                        margin: 0;
                                        padding: 5px;
                                        box-sizing: border-box;
                                    }
                                }
                                .item {
                                    display: flex;
                                    flex-flow: column;
                                    flex-shrink: 0;
                                    margin: 5px;
                                    &.missing {
                                        .preview {
                                            opacity: .5;
                                            filter: grayscale(1);
                                        }
                                    }
                                    .preview {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        height: 150px;
                                        img, video {
                                            max-height: calc(100% - 20px);
                                            max-width: calc(100% - 20px);
                                        }
                                    }
                                    .name {
                                        text-align: center;
                                        font-weight: 600;
                                        white-space: nowrap;
                                        overflow-x: hidden;
                                        text-overflow: ellipsis;
                                    }
                                    .rarity {
                                        text-align: center;
                                        font-weight: 600;
                                        font-size: .7rem;
                                        &.mb {
                                            margin-bottom: .5rem;
                                        }
                                    }
                                    .inspect {
                                        text-align: center;
                                        text-decoration: none;
                                        font-weight: 500;
                                        font-size: .85rem;
                                        color: #333333;
                                        &.mb {
                                            margin-bottom: 1rem;
                                        }
                                        &:hover {
                                            color: #000000;
                                        }
                                    }
                                    select {
                                        padding: 5px 10px;
                                        width: 100%;
                                        text-align: center;
                                        margin-bottom: .5rem;
                                    }
                                    button {
                                        padding: 5px 10px;
                                        background-color: white;
                                        border: 1px solid #333333;
                                        width: 100%;
                                        cursor: pointer;
                                        margin-bottom: .5rem;
                                    }
                                    .chance {
                                        font-size: .7rem;
                                        text-align: center;
                                    }
                                }
                            }
                            .action {
                                display: flex;
                                flex-flow: column;
                                align-items: center;
                                margin-left: auto;
                                padding: 0 2rem;
                                min-width: 220px;
                                .button {
                                    button {
                                        padding: 15px 25px;
                                        border: none;
                                        background-image: linear-gradient(90deg, var(--color-bar-1), var(--color-bar-2));
                                        color: var(--color-button, #fff);
                                        font-weight: 900;
                                        font-size: 1.2rem;
                                        outline: none;
                                        cursor: pointer;
                                        &:hover {
                                            filter: brightness(1.1);
                                        }
                                        &[disabled] {
                                            cursor: not-allowed;
                                            opacity: .5;
                                            filter: grayscale(.6);
                                        }
                                    }
                                }
                                .ending {
                                    margin-top: 1rem;
                                    text-align: center;
                                    .ending-time {
                                        margin-top: .25rem;
                                        font-weight: 600;
                                        font-size: 1.2rem;
                                    }
                                }
                            }
                        }
                    }
                }
                &.cardlabs-selector {
                    text-align: center;
                    .items {
                        user-select: none;
                        -moz-user-select: none;
                        -webkit-user-select: none;
                        -ms-user-select: none;
                        -webkit-user-drag: none;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        .item {
                            width: 200px;
                            text-align: center;
                            margin: 5px;
                            padding: 5px;
                            border: 1px solid #eeeeee;
                            border-radius: 5px;
                            cursor: pointer;
                            font-size: .8rem;
                            .name {
                                font-weight: 600;
                            }
                            .preview {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 150px;
                                width: 200px;
                                pointer-events: none;
                                img, video {
                                    max-height: calc(100% - 10px);
                                    max-width: calc(100% - 10px);
                                }
                            }
                            .mint {
                                font-size: .7rem;
                                color: #999999;
                            }
                            &.active {
                                border-color: #999999;;
                                background-color: rgb(240, 240, 240);
                            }
                            &:hover {
                                border-color: #333333;;
                            }
                        }
                    }
                }
                &.cardlabs-results {
                    text-align: center;
                    .action {
                        display: flex;
                        justify-content: center;
                        margin-left: auto;
                        button {
                            height: 60px;
                            width: 900px;
                            max-width: 100%;
                            cursor: pointer;
                            border: none;
                            background-image: linear-gradient(90deg, var(--color-bar-1), var(--color-bar-2));
                            color: var(--color-button, #fff);
                            font-weight: 700;
                            border-radius: 10px;
                            outline: none;
                            &[disabled] {
                                opacity: .5;
                                cursor: not-allowed;
                            }
                            &:hover {
                                filter: brightness(1.1);
                            }
                        }
                    }
                }
                &.faq {
                    width: 1200px;
                    max-width: calc(100% - 50px);
                    margin: 0 auto;
                    .questions {
                        .question {
                            position: relative;
                            background: var(--faq-background);
                            padding: 25px;
                            margin-bottom: 25px;
                            .phrase {
                                color: var(--faq-title-color);
                                user-select: none;
                                -moz-user-select: none;
                                -webkit-user-select: none;
                                -ms-user-select: none;

                                position: relative;
                                background: var(--faq-background);
                                padding: 25px;
                                margin: -25px;
                                margin-bottom: -50px;
                                z-index: 2;
                                font-size: 1.5rem;
                                font-weight: 900;
                                cursor: pointer;
                            }
                            .answer {
                                color: var(--faq-text-color);
                                margin-top: 25px;
                                z-index: 1;
                                max-height: 0;
                                overflow: hidden;
                                transition: max-height 0s, opacity 0.5s, margin-top 0.5s;
                                opacity: 0;
                                font-weight: 600;
                                font-size: 1.05rem;
                                p {
                                    margin: 0;
                                    margin-bottom: 15px;
                                    padding: 0;
                                }
                                a {
                                    color: var(--faq-text-highlight-color);
                                }
                            }
                            &.toggled {
                                .answer {
                                    margin-top: 50px;
                                    max-height: 1000px;
                                    opacity: 1;
                                    transition: max-height 2s, opacity 1s, margin-top 1s;
                                }
                            }
                        }
                    }
                }
                &.pack-list {
                    min-height: 58vh;
                    .pending-pack {
                        margin: 10px auto;
                        padding: 10px 15px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 600px;
                        max-width: 100%;
                        background: var(--shop-packlist-item-button-background);
                        color: var(--shop-packlist-item-button-color);
                        border-radius: 5px;
                        button {
                            border: 2px solid var(--shop-packlist-item-button-color);
                            color: white;
                            background-color: var(--shop-packlist-item-button-color);
                            cursor: pointer;
                            padding: 5px 10px;
                            font-weight: 600;
                            border-radius: 5px;
                            filter: none;
                            transition: filter 0.2s;
                            &:hover {
                                filter: brightness(1.1);
                            }
                        }
                    }
                    .inner {
                        display: flex;
                        justify-content: center;
                        flex-wrap: wrap;
                        .pack {
                            display: flex;
                            margin: 10px;
                            background: var(--shop-packlist-item-background, rgba(255, 255, 255, 0.8));
                            padding: 25px;
                            border-radius: 10px;
                            width: 600px;
                            border: 1px solid var(--shop-packlist-item-border-color);
                            border-top: 6px solid var(--shop-packlist-item-border-top-color);
                            .image-container {
                                display: inline-block;
                                width: 200px;
                                max-width: 100%;
                                height: 200px;
                                max-height: 200px;
                                margin-right: 25px;

                                .preview {
                                    text-align: center;
                                    height: inherit;
                                    max-height: 100%;
                                    width: inherit;
                                    max-width: 100%;

                                    img, video {
                                        max-width: 100%;
                                        max-height: 100%;
                                        filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
                                    }
                                }
                            }
                            .data {
                                display: flex;
                                flex-flow: column;
                                color: var(--shop-packlist-item-data-color);
                                flex-grow: 1;
                                font-size: 1.2rem;
                                h4 {
                                    font-size: 1.3rem;
                                    margin-bottom: .8rem;
                                }
                                .row {
                                    display: flex;
                                    align-items: center;
                                    &.desc {
                                        flex-flow: column;
                                        align-items: flex-start;
                                    }
                                    &.starttext {
                                        flex-flow: column;
                                        text-align: center;
                                        font-size: 1.2rem;
                                        margin-bottom: -10px;
                                    }
                                    &.mt-1 {
                                        margin-top: 10px;
                                    }
                                    &.mb-1 {
                                        margin-bottom: 10px;
                                    }
                                    .key {
                                        font-weight: 700;
                                        margin-right: .5rem;
                                    }
                                    .value {
                                        margin-left: auto;
                                        order: 2;
                                        font-weight: 400;
                                        font-size: 1.1rem;
                                        text-align: right;
                                    }
                                    .desc-row {
                                        min-height: 1rem;
                                        font-size: 0.9rem;
                                        font-weight: 600;
                                    }
                                }
                                a,.bottom {
                                    margin-top: auto;
                                }
                                input {
                                    padding: 10px 25px;
                                    width: calc(100% - 50px);
                                    outline: none;
                                    border: none;
                                    border-radius: 10px;
                                    margin-top: 15px;
                                }
                                select {
                                    width: 100%;
                                }
                                button {
                                    margin-top: 15px;
                                    background: var(--shop-packlist-item-button-background);
                                    outline: none;
                                    border: none;
                                    color: var(--shop-packlist-item-button-color);
                                    padding: 10px 25px;
                                    width: 100%;
                                    border-radius: 10px;
                                    cursor: pointer;
                                    font-size: 1.2rem;
                                    font-weight: 600;
                                    &:disabled {
                                        filter: opacity(0.5) brightness(0.8);
                                        cursor: not-allowed;
                                    }
                                    &:hover:not(:disabled) {
                                        filter: brightness(1.2);
                                    }
                                }
                            }
                            &.redeem {
                                text-align: center;
                                .data {
                                    align-items: center;
                                    h4 {
                                        font-weight: 400;
                                        font-size: 1rem;
                                        line-height: 1rem;
                                    }
                                    img, video {
                                        width: 250px;
                                        max-width: 100%;
                                    }
                                    a {
                                        font-size: .8rem;
                                        color: black;
                                        text-decoration: none;
                                        cursor: pointer;
                                    }
                                }
                            }
                        }
                    }
                    .enter-queue {
                        display: flex;
                        justify-content: space-evenly;
                        align-items: center;
                        gap: 3rem;
                        border-top: 6px solid var(--shop-packlist-item-border-top-color);
                        background-color: var(--shop-packlist-item-background);
                        margin: 0 auto 1.5rem;
                        padding: 4rem 3rem;
                        width: 76.3rem;

                        .brand-logo {
                            max-width: 6rem;
                        }

                        .wax-logo {
                            max-width: 7rem;
                        }

                        .start-text {
                            max-width: 21rem;
                            color: var(--shop-packlist-item-data-color, black);
                        }

                        button {
                            font-weight: 700;
                            font-family: sans-serif;
                            background-color: var(
                                --shop-single-multiple-packs-enter-queue-button-background,
                                var(--shop-packlist-item-button-background)
                            );
                            color: var(--shop-packlist-item-button-color);
                        }

                        @media only screen and (max-width: 1277px) {
                            width: auto;
                            margin: 0 0.6rem 1.5rem;
                        }

                        @media only screen and (max-width: 800px) {
                            padding: 2rem;
                            gap: 1rem;
                        }

                        @media only screen and (max-width: 600px) {
                            flex-wrap: wrap;
                            text-align: center;
                        }
                    }
                    &.sale, &.unbox {
                        .empty {
                            text-align: center;
                            display: flex;
                            flex-flow: column;
                            justify-content: center;
                            margin-bottom: 2rem;
                            .links {
                                display: flex;
                                justify-content: center;
                                flex-wrap: wrap;
                                button {
                                    margin: 5px;
                                    border: none;
                                    padding: 15px 30px;
                                    border-radius: 5px;
                                    cursor: pointer;
                                    font-weight: 700;
                                    background: var(--shop-packlist-item-button-background);
                                    color: var(--shop-packlist-item-button-color);
                                    transition: box-shadow .2s;
                                    &:hover {
                                        // filter: brightness(.8);
                                        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
                                    }
                                }
                            }
                        }
                    }
                    .oddslink {
                        text-align: center;
                        font-size: 1rem;
                        margin-bottom: 1rem;
                        a {
                            color: black;
                            text-decoration: none;
                        }
                    }
                }
                &.catalog {
                    display: flex;
                    gap: 2rem;
                    aside {
                        flex-shrink: 0;
                        &.filters {
                            .filter {
                                margin-bottom: 25px;

                                user-select: none;
                                -moz-user-select: none;
                                -webkit-user-select: none;
                                -ms-user-select: none;
                                .title {
                                    display: flex;
                                    color: var(--catalog-filter-title-color);
                                    font-size: 1.2rem;
                                    font-weight: 700;
                                    margin-bottom: 5px;
                                    .right {
                                        margin-left: auto;
                                        &.close {
                                            display: none;
                                            font-size: 0.8rem;
                                            font-weight: 500;
                                            cursor: pointer;
                                            @media only screen and (max-width: 650px) {
                                                display: block;
                                            }
                                        }
                                    }
                                }
                                .options {
                                    max-height: 360px;
                                    overflow-y: auto;
                                    .option {
                                        display: flex;
                                        align-items: flex-start;
                                        color: var(--catalog-filter-option-color);
                                        font-weight: 300;
                                        cursor: pointer;
                                        margin-bottom: 5px;
                                        .selector {
                                            height: 0.7rem;
                                            width: 0.7rem;
                                            border-radius: 1rem;
                                            margin-right: 5px;
                                            margin-top: 4.5px;
                                            border: 1px solid var(--catalog-filter-option-border-color);
                                            background-color: transparent;
                                            overflow: hidden;
                                        }
                                        &:hover {
                                            color: var(--catalog-filter-option-selected-color);
                                        }
                                        &.selected {
                                            color: var(--catalog-filter-option-selected-color);
                                            .selector {
                                                background: var(--catalog-filter-option-selected-background);
                                                border: 1px solid transparent;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .container {
                        flex-shrink: 1;
                        .series-container {
                            overflow-x: auto;
                            width: 100%;
                            margin-bottom: 15px;
                            .series-list {
                                display: flex;
                                flex-wrap: nowrap;
                                margin-bottom: 10px;
                                .series {
                                    display: flex;
                                    background-color: var(--catalog-button-background-color, #333333);
                                    background-size: cover;
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    margin-right: 10px;
                                    justify-content: center;
                                    align-items: center;
                                    min-width: 120px;
                                    height: 40px;
                                    font-size: 1.2rem;
                                    color: var(--catalog-button-text-color, #ffffff);
                                    font-weight: 700;
                                    border-radius: 10px;
                                    padding: 10px 40px;
                                    text-decoration: none;
                                    opacity: 0.6;
                                    cursor: pointer;
                                    filter: brightness(0.8);
                                    transition: filter 0.2s, opacity 0.2s;
                                    &:hover {
                                        opacity: 0.7;
                                        filter: brightness(1);
                                    }
                                    &.active {
                                        opacity: 1;
                                        filter: brightness(1.3);
                                    }
                                }
                            }
                        }
                        .search {
                            display: flex;
                            flex-wrap: wrap;
                            margin-bottom: 25px;
                            .bar {
                                display: flex;
                                border: 1px solid var(--search-border-color);
                                &:focus-within {
                                    border-color: var(--search-active-border-color);
                                }
                                background: var(--search-background);
                                border-radius: 10px;
                                flex-grow: 1;
                                margin-right: 10px;
                                width: 350px;
                                max-width: 100%;
                                align-items: center;
                                input {
                                    border: none;
                                    background-color: transparent;
                                    height: 40px;
                                    padding: 5px 15px;
                                    flex-grow: 1;
                                    outline: none;
                                    color: var(--search-color);
                                    &::placeholder {
                                        color: var(--search-placeholder-color);
                                    }
                                }
                            }
                        }
                        .cards {
                            .list {
                                display: flex;
                                flex-wrap: wrap;
                                align-items: center;
                                justify-content: center;
                                width: 100%;

                                .card {
                                    background-color: var(--catalog-card-background-color, #eeedef);
                                    padding: 0.5rem;
                                    border-radius: 10px;
                                    box-sizing: border-box;
                                    margin: 10px;
                                    text-align: center;

                                    .image-container {
                                        display: inline-block;
                                        width: 260px;
                                        max-width: 100%;
                                        height: 260px;
                                        max-height: 260px;

                                        .preview {
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            height: inherit;
                                            padding: 0.5rem;
                                            border-radius: 10px;
                                            background-color: var(--catalog-card-inner-background-color, #eeedef);

                                            img, video {
                                                max-width: 100%;
                                                max-height: 100%;
                                            }
                                        }
                                    }

                                    .name {
                                        color: black;
                                        font-size: 1.2rem;
                                        font-weight: 600;
                                    }

                                    .rarity {
                                        color: black;
                                        font-weight: 300;
                                        font-size: 1rem;
                                    }
                                }
                            }
                            .pagination {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                .pages {
                                    margin: 0 10px;
                                }
                                button {
                                    user-select: none;
                                    -moz-user-select: none;
                                    -webkit-user-select: none;
                                    -ms-user-select: none;
                                    padding: 10px;
                                    background: var(--catalog-pagination-background);
                                    color: var(--catalog-pagination-color);
                                    border: none;
                                    border-radius: 10px;
                                    cursor: pointer;
                                    transition: background-color 0.2s;
                                    outline: none;
                                    &:hover {
                                        background: var(--catalog-pagination-hover-background);
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &#info {
                .top {
                    background-position: center;
                    background-size: contain;
                    background-color: var(--top-background);
                    background-repeat: no-repeat;
                    min-height: calc(100vh - 68px);
                    &.LearnMore {
                        display: flex;
                        flex-flow: column;
                    }
                }
                header {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: calc(100% - 100px);
                    flex-flow: column;
                    overflow: hidden;
                    max-width: 100%;
                    flex-grow: 1;
                    .cards {
                        display: flex;
                        margin-bottom: 25px;
                        flex-wrap: wrap;
                        justify-content: center;

                        img, video {
                            max-width: 300px;
                            max-height: 300px;
                            margin: 10px;
                        }
                    }
                    .cards.cards-small {
                        margin-bottom: 0;
                        img, video {
                            max-width: 200px;
                            max-height: 200px;
                            margin: 10px;
                        }
                    }
                    .brand {
                        max-height: 100px;
                        margin-top: 15px;
                        margin-bottom: 15px;
                    }
                    .brand-2x {
                        max-height: 160px;
                        margin-bottom: 15px;
                    }
                    .brand-3x {
                        max-height: 240px;
                        overflow: visible;
                    }
                    .brand, .brand-2x, .brand-3x {
                        width: 100%;
                        text-align: center;
                        img {
                            max-width: calc(100% - 50px);
                            max-height: 100%;
                        }
                    }
                    .title-text {
                        text-align: left;
                        font-weight: 400;
                        font-size: 7rem;
                        line-height: 1;
                        text-transform: uppercase;
                        max-width: 1000px;
                        color: var(--topnav-link-color, #ffffff);
                        margin-top: -2rem;
                    }
                    .blurb {
                        text-align: center;
                        font-weight: 500;
                        width: 100%;
                        color: var(--topnav-link-color, #ffffff);
                    }
                    button {
                        background-image: linear-gradient(90deg, var(--color-bar-1), var(--color-bar-2));
                        border: none;
                        padding: .75rem 1.5rem;
                        color: var(--color-button, #fff);
                        font-weight: 800;
                        font-size: 1.2rem;
                        margin-top: 15px;
                        border-radius: 2rem;
                        line-height: 1.2;
                        cursor: pointer;
                        outline: none;
                        min-width: 200px;
                    }
                }
                section {
                    &.why-blockchain {
                        display: flex;
                        flex-flow: column;
                        align-items: center;
                        text-align: center;
                        background-repeat: no-repeat;
                        background-size: 30%;
                        h1 {
                            color: var(--blockchain-title-color);
                            font-size: 4rem;
                            max-width: calc(100% - 50px);
                            margin-top: 50px;
                        }
                        p {
                            color: var(--blockchain-text-color);
                            font-size: 1.3rem;
                            width: 1200px;
                            max-width: calc(100% - 50px);
                        }
                        .boxes {
                            display: grid;
                            gap: 50px;
                            grid-template-columns: 1fr 1fr 1fr;
                            grid-template-rows: auto;
                            margin-top: 25px;
                            overflow: auto hidden;
                            max-width: 100%;
                            padding-bottom: 25px;
                            .box-container {
                                display: flex;
                                justify-content: space-between;
                                flex-flow: column;
                                width: 300px;
                                gap: 1rem;
                                .box-img {
                                    max-width: 100%;
                                }
                                .box {
                                    &:last-child {
                                        margin-top: auto;
                                    }
                                    display: flex;
                                    flex-flow: column;
                                    overflow: hidden;
                                    border-radius: 10px;
                                    color: var(--blockchain-box-color);
                                    background: var(--blockchain-box-background);
                                    .content {
                                        padding: 10px;
                                        min-height: 120px;
                                        &#keyval {
                                            .obj {
                                                display: flex;
                                                justify-content: space-between;
                                                text-transform: capitalize;
                                                margin-bottom: 5px;
                                            }
                                        }
                                        &#table {
                                            table {
                                                width: 100%;
                                            }
                                        }
                                        &#text {
                                            display: flex;
                                            align-items: center;
                                            font-size: 1.2em;
                                        }

                                        &#svg {
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            svg {
                                                max-height: 100%;
                                                max-width: 100%;
                                            }
                                        }
                                    }
                                    .title {
                                        margin-top: auto;
                                        padding: 10px;
                                        font-weight: 600;
                                        color: var(--blockchain-box-title-color);
                                        background: var(--blockchain-box-title-background);
                                    }
                                }
                            }
                        }
                    }
                    &.how-it-works, &.blockchain-faq {
                        padding: 5rem 2.5rem;
                        text-align: center;

                        h2 {
                            text-transform: capitalize;
                            font-size: 2rem;
                            margin-bottom: 5rem;
                        }
                    }
                    &.how-it-works {
                        background: var(--how-it-works-background, #ffffff);
                        color: var(--how-it-works-text-color, #000000);

                        .explain {
                            display: flex;
                            justify-content: space-between;
                            align-items: flex-start;
                            align-content: stretch;
                            flex-wrap: wrap;

                            .step {
                                display: block;
                                flex-grow: 1;
                                flex-shrink: 0;
                                flex-basis: 0;
                                padding: 0 2.5rem;
                                color: var(--how-it-works-text-color, #000000);
                                text-decoration: none;
                                min-width: 300px;

                                img {
                                    width: 100%;
                                    min-width: 150px;
                                    max-width: 200px;
                                }

                                p {
                                    font-size: 1.2rem;
                                    font-weight: 600;
                                }
                            }
                        }
                    }
                    &.blockchain-faq {
                        background: var(--blockchain-faq-background, #ffffff);
                        color: var(--blockchain-faq-text-color, #000000);

                        h2 {
                            margin-bottom: 3rem;
                        }

                        .questions {
                            text-align: left;

                            .question {
                                position: relative;
                                background: var(--faq-background);
                                padding: 25px;
                                margin-bottom: 25px;

                                .phrase {
                                    color: var(--faq-title-color);
                                    user-select: none;
                                    -moz-user-select: none;
                                    -webkit-user-select: none;
                                    -ms-user-select: none;

                                    position: relative;
                                    background: var(--faq-background);
                                    padding: 25px;
                                    margin: -25px;
                                    margin-bottom: -50px;
                                    z-index: 2;
                                    font-size: 1.5rem;
                                    font-weight: 900;
                                    cursor: pointer;
                                }

                                .answer {
                                    color: var(--faq-text-color);
                                    margin-top: 25px;
                                    z-index: 1;
                                    max-height: 0;
                                    overflow: hidden;
                                    transition: max-height 0s, opacity 0.5s, margin-top 0.5s;
                                    opacity: 0;
                                    font-weight: 600;
                                    font-size: 1.05rem;

                                    p {
                                        margin: 0;
                                        margin-bottom: 15px;
                                        padding: 0;
                                    }

                                    a {
                                        color: var(--faq-text-highlight-color);
                                    }
                                }

                                &.toggled {
                                    .answer {
                                        margin-top: 50px;
                                        max-height: 1000px;
                                        opacity: 1;
                                        transition: max-height 2s, opacity 1s, margin-top 1s;
                                    }
                                }
                            }
                        }
                    }
                    &.complete-set {
                        padding: 35px;
                        background: var(--completeset-background);
                        .inner {
                            width: 1200px;
                            max-width: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin: 0 auto;
                            .preview {
                                min-width: 450px;
                                margin-right: 25px;
                                padding: 25px;
                                img, video {
                                    max-width: calc(100% - 50px);
                                    border-radius: 5px;
                                    border: 1px solid var(--completeset-image-border-color);
                                    box-shadow: 0 2px 25px rgba(0, 0, 0, 0.2);
                                }
                            }
                            .content {
                                h2 {
                                    color: var(--completeset-title-color);
                                    font-size: 3rem;
                                }
                                p {
                                    color: var(--completeset-text-color);
                                    font-weight: 600;
                                }
                                button {
                                    background: var(--completeset-button-background);
                                    color: var(--completeset-button-color);
                                    padding: 15px;
                                    width: 300px;
                                    border: none;
                                    cursor: pointer;
                                    outline: none;
                                    font-size: 1.2rem;
                                    font-weight: 600;
                                    border-radius: 10px;
                                }
                            }
                        }
                    }
                    &.burn-to-earn {
                        text-align: center;
                        padding: 3rem 1rem;
                        border-bottom: 5px solid rgba(4,30,66,1);
                        .heading {
                            margin-bottom: 1rem;
                            h2 {
                                font-weight: 700;
                                font-style: normal;
                                font-size: 40px;
                            }
                        }
                        .overview {
                            max-width: 700px;
                            margin: 0 auto;
                            video {
                                width: 100%;
                                max-width: 600px;
                            }
                            img {
                                width: 100%;
                                max-width: 600px;
                            }
                            p {
                                font-weight: 400;
                                font-style: normal;
                                font-size: 24px;
                            }
                        }

                        button {
                            background-image: linear-gradient(90deg, var(--color-bar-1), var(--color-bar-2));
                            border: none;
                            padding: .75rem 1.5rem;
                            color: var(--color-button, #fff);
                            font-weight: 800;
                            font-size: 1.2rem;
                            margin-top: 1rem;
                            border-radius: 2rem;
                            line-height: 1.2;
                            cursor: pointer;
                            outline: none;
                            min-width: 200px;
                        }
                    }
                    &.rarities, &.card-selection {
                        padding: 50px;
                        overflow: hidden;
                        h1 {
                            color: var(--rarities-title-color, #000000);
                            text-align: center;
                            text-transform: capitalize;
                            font-size: 3rem;
                            margin-bottom: 50px;
                        }
                        .overview {
                            padding: 3rem 0;
                            text-align: center;
                            font-size: 1.1rem;

                            h2 {
                                color: var(--rarities-title-color, #000000);
                                text-transform: uppercase;
                                font-size: 1.5rem;
                            }
                        }
                        .inner {
                            padding: 25px;
                            .rarities-list {
                                animation: flow 30s linear infinite;
                                display: inline-flex;
                                flex-wrap: nowrap;
                                justify-content: center;
                                min-width: 100%;
                                .rarity-box {
                                    flex-flow: column;
                                    text-align: center;
                                    margin: 1rem;
                                    height: 260px;
                                    flex-shrink: 0;
                                    align-self: baseline;
                                    img, video {
                                        max-height: 100%;
                                        margin-bottom: 25px;
                                        //filter: drop-shadow(0 1px 10px rgba(0, 0, 0, 0.2));
                                    }
                                    .name {
                                        color: var(--rarities-name-color, #000000);
                                        font-size: 1.5rem;
                                        font-weight: 600;
                                        margin-bottom: 5px;
                                    }
                                    .rarity {
                                        color: var(--rarities-extra-color, #000000);
                                        font-weight: 300;
                                        font-size: 1.2rem;
                                    }
                                    .rarity-soft {
                                        color: var(--rarities-extra-color, #000000);
                                        font-weight: 300;
                                        font-size: 0.9rem;
                                    }
                                }
                            }
                        }
                    }
                    &.card-selection {
                        background: var(--card-selection-background, #ffffff);
                        color: var(--card-selection-text-color, #000000);
                        text-align: center;

                        button {
                            background-image: linear-gradient(90deg, var(--color-bar-1), var(--color-bar-2));
                            border: none;
                            padding: .75rem 1.5rem;
                            color: var(--color-button, #fff);
                            font-weight: 800;
                            font-size: 1.2rem;
                            margin-top: 5rem;
                            border-radius: 2rem;
                            line-height: 1.2;
                            cursor: pointer;
                            outline: none;
                            min-width: 200px;
                        }
                    }
                }
            }

            &#login {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-flow: column;
                h1 {
                    color: var(--login-title-color);
                    letter-spacing: 0.1rem;
                }
                hr {
                    border-color: var(--login-line-color);
                    border-style: solid;
                    width: 150px;
                    margin-bottom: 25px;
                    margin-top: 20px;
                }
                button {
                    padding: 5px 25px;
                    width: 350px;
                    font-size: 1.2rem;
                    font-weight: 900;
                    letter-spacing: -1.5px;
                    background: var(--login-button-background);
                    color: var(--login-button-color);
                    border: 2px solid transparent;
                    cursor: pointer;
                    outline: none;
                    transition: background-color 0.2s, color 0.2s;
                    &:hover {
                        background: var(--login-button-hover-background);
                        color: var(--login-button-hover-color);
                    }
                }
            }
        }

        &.layout-topps {
            main {
                height: unset;
                overflow-y: unset;
                section {
                    &.topnav {
                        position: fixed;
                        top: 0;
                        width: 100%;
                        box-sizing: border-box;
                        z-index: 100;
                        margin-bottom: auto;
                    }
                    &.title-section,
                    &.catalog {
                        margin-top: 100px;
                    }
                }
                &#info {
                    .top {
                        min-height: calc(100vh);
                        &.LearnMore {
                            header {
                                margin-top: auto;
                            }
                        }
                    }
                }
            }
            main#unboxing {
                section {
                    &.title-section {
                        margin-top: 0;
                    }
                }
            }
        }
    }
}

.graphImage {
    background-color: transparent;
    .axis-line {
        stroke: var(--graph-axis-color);
    }
    .line-color {
        stroke: var(--graph-line-color);
    }
    .grid-lines {
        stroke: var(--graph-grid-color);
        stroke-opacity: 0.05;
    }
    .dot {
        fill: var(--graph-dot-color);
    }
}

@media only screen and (max-width: 1100px) {
    #root {
        .app-container {
            nav.nav-original {
                .navigation {
                    .links {
                        a, .link {
                            padding: .5rem .75rem;
                            font-size: 1.2rem;
                        }
                    }
                }
            }
            main {
                &#info {
                    section {
                        &.complete-set {
                            .inner {
                                flex-wrap: wrap;
                                align-items: center;
                                justify-content: center;
                                .preview {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    margin-right: 0;
                                    min-width: initial;
                                    height: 300px;
                                    img, video {
                                        max-width: 100%;
                                        max-height: 100%;
                                    }
                                }
                                .content {
                                    text-align: center;
                                    max-width: calc(100% - 20px);
                                    p {
                                        width: 700px;
                                        max-width: 100%;
                                        margin: 10px auto;
                                    }
                                    button {
                                        margin-top: 25px;
                                        padding: 10px 15px;
                                        font-size: 1rem;
                                    }
                                }
                            }
                        }
                    }
                    header {
                        .cards {
                            img, video {
                                max-width: 190px;
                                max-height: 250px;
                            }
                        }
                        .cards.cards-small {
                            img, video {
                                max-width: 160px;
                                max-height: 200px;
                            }
                        }
                        .title-text {
                            font-size: 5rem;
                            max-width: 800px;
                        }
                        .blurb {
                            box-sizing: border-box;
                            padding: 0 2rem;
                            font-size: .8rem;
                        }
                    }
                }
                section {
                    &.topnav {
                        padding: 1rem;
                        .signin {

                            .link {
                                font-size: 0.8rem;
                                padding: .5rem .5rem;
                                margin: 0 .5rem 0 0;
                            }
                        }
                    }
                    &.topnav.hasbro {
                        padding: 0;
                        .signin {
                            .link {
                                font-size: 1rem;
                                border-radius: 0;
                                padding: 0px 1.4rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 950px) {
    #root {
        .app-container {
            nav.nav-original {
                .navigation {
                    .links {
                        a, .link {
                            padding: .5rem .5rem;
                            font-size: 1rem;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 800px) {
    #root {
        .app-container {
            .topnav.hasbro {
                .signin {
                    .logo-link {
                        .logo {
                            height: 30px;
                        }
                    }
                }
            }
            nav.nav-original {
                .navigation {
                    .links {
                        width: 100%;
                        margin: 0;
                        padding: 0;
                        justify-content: space-evenly;
                        flex-wrap: wrap;
                        a, .link {
                            &.share {
                                display: none;
                            }
                            padding: .25rem .5rem;
                            font-size: 1rem;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 650px) {
    .mobileonly {
        display: inherit;
    }
    #root {
        .app-container {
            nav.nav-original {
                .navigation {
                    .logo {
                        display: none;
                    }
                    .links {
                        a, .link {
                            &.share {
                                display: none;
                            }
                            padding: .25rem .5rem;
                            font-size: 0.8rem;
                        }
                    }
                }
            }
            main {
                &#info {
                    .LearnMore {
                        min-height: fit-content;
                    }
                    section {
                        &.how-it-works, &.blockchain-faq {
                            padding: 3rem 2rem;
                        }
                        &.how-it-works {
                            h2 {
                                margin-bottom: 2rem;
                            }
                            .explain {
                                .step {
                                    padding: 0;

                                    img {
                                        max-width: 150px;
                                    }
                                    p {
                                        font-size: 1rem;
                                        font-weight: 500;
                                    }
                                }
                            }
                        }
                        &.complete-set {
                            padding: 25px 15px;
                            .inner {
                                .preview {
                                    min-width: 100%;
                                    height: auto;
                                }
                                .content {
                                    h2 {
                                        font-size: 2rem;
                                    }
                                    p {
                                        font-size: 0.92rem;
                                        font-weight: 400;
                                    }
                                    button {
                                        max-width: 100%;
                                    }
                                }
                            }
                        }
                        &.burn-to-earn {
                            .heading {
                                padding: 0 2rem;
                                h2 {
                                    font-size: 30px;
                                }
                            }
                            .overview {
                                padding: 0 2rem;
                                p {
                                    font-size: 16px;
                                }
                            }
                        }
                        &.blockchain-faq {
                            .questions {
                                .question {
                                    .phrase {
                                        font-size: 1.2rem;
                                    }
                                }
                            }
                        }
                        &.why-blockchain {
                            h1 {
                                font-size: 1.8rem;
                                margin-top: 0;
                            }
                            p {
                                font-size: 0.92rem;
                            }
                            .boxes {
                                display: flex;
                                flex-direction: column;

                                .box-container {
                                    gap: 2rem;
                                }
                            }
                        }
                        &.rarities {
                            h1 {
                                font-size: 2rem;
                                margin-bottom: 0;
                            }
                            .inner {
                                .rarities-list {
                                    animation: flow 60s linear infinite;
                                    .rarity-box {
                                        margin: 0.5rem;
                                        height: 200px;
                                        img, video {
                                            margin-bottom: 5px;
                                        }
                                        .name {
                                            font-size: 1rem;
                                        }
                                        .rarity {
                                            font-size: 0.9rem;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    header {
                        justify-content: flex-start;
                        padding-top: 15px;
                        padding-bottom: 15px !important;
                        .cards {
                            img, video {
                                max-width: 120px;
                                max-height: 200px;
                            }
                        }
                        .cards.cards-small {
                            img, video {
                                max-width: 100px;
                                max-height: 180px;
                            }
                        }
                        .title-text {
                            font-size: 2.5rem;
                            max-width: 500px;
                        }
                    }
                }
                section {
                    &.topnav {
                        .signin {
                            justify-content: space-evenly;
                            flex-wrap: wrap;
                            height: auto;
                            .profile {
                                display: none;
                            }
                            a, .link {
                                font-size: 0.8rem;
                                padding: .5rem .5rem;
                                margin: 0 .5rem 0 0;
                            }
                        }
                    }
                    &.topnav.hasbro {
                        padding: 0;
                        .signin {
                            flex-wrap: nowrap;
                            .logo-link {
                                padding: 0 0.5rem;
                                .logo {
                                    height: 35px;
                                    max-width: 16rem;
                                }
                            }
                            .right {
                                .link {
                                    font-size: 0.8rem;
                                }
                                .hasbro-link {
                                    display: none;
                                }
                            }
                        }
                    }
                    &.title-section {
                        h1 {
                            &.faq {
                                font-size: 2rem;
                            }
                        }
                    }
                    &.pack-list {
                        .inner {
                            .pack {
                                flex-wrap: wrap;
                                justify-content: center;
                                .preview {
                                    margin: 0;
                                    margin-bottom: 15px;
                                }
                                .data {
                                    font-size: 1rem;
                                    h4 {
                                        font-size: 1.2rem;
                                    }
                                    .row {
                                        .value {
                                            font-size: .8rem;
                                        }
                                        &.desc {
                                            .desc-row {
                                                min-height: 1rem;
                                                font-size: .8rem;
                                                font-weight: 600;
                                            }
                                        }
                                        &.starttext {
                                            font-size: 1rem;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &.catalog {
                        position: relative;
                        .filters {
                            display: none;
                        }
                        .filters.active {
                            display: block;
                            position: absolute;
                            background-color: var(--app-background, white);
                            z-index: 1;
                            width: 100%;
                            padding-bottom: 3.5rem;
                            .filter {
                                .options {
                                    max-height: none;
                                }
                            }
                        }
                        .container {
                            display: none;
                        }
                        .container.active {
                            display: block;
                            width: 100%;
                            .series-container {
                                .series-list {
                                    flex-wrap: wrap;
                                    margin-bottom: 0;
                                    gap: 1rem 0;
                                    justify-content: center;
                                    .series {
                                        height: 25px;
                                        font-size: 1rem;
                                        padding: 0 10px;
                                    }
                                }
                            }
                            .search {
                                .bar {
                                    input {
                                        padding-right: 0;
                                    }
                                    .submit {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        cursor: pointer;
                                        margin-right: 10px;
                                        svg {
                                            height: 20px;
                                            width: 20px;
                                        }
                                    }
                                }
                            }
                            .cards {
                                .list {
                                    justify-content: center;
                                }
                            }
                        }
                    }
                    &.faq {
                        padding: 15px;
                        max-width: calc(100% - 30px);
                        .questions {
                            .question {
                                .phrase {
                                    font-size: 1rem;
                                }
                                .answer {
                                    font-size: 0.8rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 420px) {
    #root {
        .app-container {
            main {
                section.topnav.hasbro {
                    .signin {
                        .logo-link {
                            padding: 0;
                            margin: 0;
                            .logo {
                                max-width: 13rem;
                            }
                        }
                        .right {
                            .link {
                                padding: 1rem 0.5rem;
                                font-size: 0.7rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes flow {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(calc(-100% - calc(-100vw + 120px)));
    }
    100% {
        transform: translateX(0);
    }
}
